import { throttle } from "underscore";
import { fixedHeader } from "../../../_global/assets/ts/fixedHeader";
import { scrollTop } from "../../../_global/assets/ts/scrollTop";
import { imageSwapper } from "../../../_global/assets/ts/imageSwapper";
document.addEventListener("DOMContentLoaded", function () {
    var throt_scrollTop = throttle(scrollTop, 500);
    var throt_fixedHeader = throttle(fixedHeader, 50);
    document.body.onscroll = function () {
        throt_scrollTop();
        throt_fixedHeader();
    };
    imageSwapper([
        { src: "assets/image/iStock-665221916.jpg", alt: "" },
        { src: "assets/image/d_hambek_labor.png", alt: "" },
    ]);
});
