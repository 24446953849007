export function imageSwapper(image_data, container_id, img_class, timer) {
    if (container_id === void 0) { container_id = "image-swapper"; }
    if (img_class === void 0) { img_class = "swap-image"; }
    if (timer === void 0) { timer = 5; }
    var container = document.getElementById(container_id);
    var images = document.querySelectorAll("#".concat(container.id, " .").concat(img_class, ":not(.default)"));
    var currentPos = 0;
    function swapImage() {
        images[currentPos].classList.add("swap-image-hidden");
        if (++currentPos >= images.length) {
            currentPos = 0;
        }
        images[currentPos].classList.remove("swap-image-hidden");
    }
    setInterval(swapImage, timer * 1000);
}
