export function fixedHeader(containerId, headerScrollFixCls, offset) {
    if (containerId === void 0) { containerId = "header-container"; }
    if (headerScrollFixCls === void 0) { headerScrollFixCls = "fixed-top"; }
    if (offset === void 0) { offset = 480; }
    var container = document.getElementById(containerId);
    if (container) {
        var fromTop = window.pageYOffset ||
            (document.documentElement || document.body.parentNode || document.body).scrollTop;
        if (fromTop > offset) {
            container.classList.add(headerScrollFixCls);
        }
        else {
            container.classList.remove(headerScrollFixCls);
        }
    }
}
