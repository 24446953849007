export function scrollTop(containerId, offset) {
    if (containerId === void 0) { containerId = "scroll-top-container"; }
    if (offset === void 0) { offset = -1; }
    var container = document.getElementById(containerId);
    if (container) {
        if (offset == -1) {
            offset = (isNaN(window.innerHeight) ? window.clientHeight : window.innerHeight) / 2;
        }
        var fromTop = window.pageYOffset ||
            (document.documentElement || document.body.parentNode || document.body).scrollTop;
        if (fromTop > offset) {
            container.style.display = "block";
        }
        else {
            container.style.display = "none";
        }
    }
}
